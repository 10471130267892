import React from 'react';
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Text,
  Image,
  VStack,
} from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';
function Roadmap() {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const headingFontSize = isMobile ? '28px' : '42px';
  const headingFontSize2 = isMobile ? '22px' : '32px';
  const subheadingFontSize = isMobile ? '16px' : '20px';
  const headingFontColor = '#02549F';
  const headingFontFamily = 'Poppins-Bold';
  const bodyFontFamily = 'Poppins-Regular';
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Box
      width="100vw"
      textAlign="start"
      fontSize="xl"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      fontFamily={bodyFontFamily}
    >
      <Box
        maxW="65vw"
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        my={16}
        fontFamily={bodyFontFamily}
        letterSpacing="1px"
      >
        <Heading
          alignSelf="start"
          fontFamily={headingFontFamily}
          fontSize={headingFontSize}
          color={headingFontColor}
          mb={2}
          pt={24}
          pb={4}
        >
          Roadmap
        </Heading>
        <VStack
          width="65vw"
          padding={8}
          backgroundColor="rgba(255,255,255,0.33)"
        >
          <Heading
            alignSelf="start"
            fontFamily={headingFontFamily}
            fontSize={headingFontSize2}
            color={headingFontColor}
          >
            Phase 1
          </Heading>
          <Grid
            justifyContent="center"
            alignItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
            alignSelf="start"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./check.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Write randomization code, create attributes/art, deploy website
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./check.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Launch Twitter/Discord gain toeG’s and toeMentum
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./check.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Start giveaways, sneak peeks, collabs, Magic Eden approval
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./check.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                toeMART team is verified/doxxed by Civic to demonstrate
                gratitude and sense of commitment to community
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./check.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Live stream the random generation, upload, and creation of 7035
                toes to the Candy Machine. **MINT DAY** December 1st, 2021 @
                00:03 UTC
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./check.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Verification at additional secondary markets
              </Text>
            </GridItem>
          </Grid>
        </VStack>
        <br></br>
        <br></br>
        <VStack w="65vw" padding={8} backgroundColor="rgba(255,255,255,0.33)">
          <Heading
            alignSelf="start"
            fontFamily={headingFontFamily}
            fontSize={headingFontSize2}
            color={headingFontColor}
          >
            Phase 2
          </Heading>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                The Big Bad Wolf begins to hunt, sweeping the floor of Toes
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                1/1 NFT Reward Airdrops
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Grape verification for Discord members
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Deploy internal Rarity tool
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Establish toeDAO
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Giveaway determined by DAO to celebrate successful launch!
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Secret mechanic following Wolf hunts
              </Text>
            </GridItem>
          </Grid>
        </VStack>
        <br></br>
        <br></br>
        <VStack w="65vw" padding={8} backgroundColor="rgba(255,255,255,0.33)">
          <Heading
            alignSelf="start"
            fontFamily={headingFontFamily}
            fontSize={headingFontSize2}
            color={headingFontColor}
          >
            Phase 3
          </Heading>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Deploy minigames holders can play with their NFTs
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Begin airdrops and financially back $TOEKEN
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Trading Post creation, work within DAO to determine future
                project logistics
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                3D / High Res modeling sneak peeks
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Open source everything, determine projects and goals to improve
                and contribute to broader Solana ecosystem
              </Text>
            </GridItem>
          </Grid>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                Help friends, wherever possible, develop, engineer, launch, and
                deliver Solana projects
              </Text>
            </GridItem>
          </Grid>
        </VStack>
        <br></br>
        <br></br>
        <VStack w="65vw" padding={8} backgroundColor="rgba(255,255,255,0.33)">
          <Heading
            alignSelf="start"
            fontFamily={headingFontFamily}
            fontSize={headingFontSize2}
            color={headingFontColor}
          >
            Phase 4 +
          </Heading>
          <Grid
            alignSelf="start"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            justifyItems="center"
            gridTemplateColumns="auto auto"
            gridTemplateRows="1fr"
          >
            <GridItem>
              <Image
                mr={4}
                minW="50px"
                maxW="75px"
                src="./uncheck.png"
                alt="checkbox"
              />
            </GridItem>
            <GridItem>
              <Text fontSize={subheadingFontSize} fontFamily={bodyFontFamily}>
                The course of actions in our future will be determined with the
                blessing of the toeDAO. We will continue to iterate and expand
                on this roadmap as we trudge the road of happy destiny
              </Text>
            </GridItem>
          </Grid>
        </VStack>
      </Box>
      {scrollPosition > 500 && (
        <Box
          position="fixed"
          bottom="20px"
          right={['16px', '84px']}
          zIndex={1}
          cursor="pointer"
          onClick={() => scrollToTop()}
        >
          <Image src="top.png" w="60px" h="60px" />
        </Box>
      )}
    </Box>
  );
}

export default Roadmap;
