import React from 'react';
import { Box, Image, Heading, Text, Link } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

function Team() {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const headingFontSize = isMobile ? '28px' : '42px';
  const subheadingFontSize = isMobile ? '18px' : '24px';
  const headingFontColor = '#02549F';
  const headingFontFamily = 'Poppins-Bold';
  const bodyFontFamily = 'Poppins-Regular';
  const textFontSize = isMobile ? '12px' : '18px';
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box
      width="100vw"
      textAlign="start"
      fontSize={textFontSize}
      fontFamily={bodyFontFamily}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <br></br>
      <Box
        maxW="65vw"
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        letterSpacing="1px"
      >
        <Heading
          alignSelf="start"
          fontFamily={headingFontFamily}
          fontSize={headingFontSize}
          color={headingFontColor}
          pt={24}
        >
          Meet the Team
        </Heading>
        <br></br>
        <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
          One of our major sources of inspiration to get into NFTs stems
          directly from our days growing up playing Runescape Classic and World
          of Warcraft. We developed our friendships through the botting/private
          server communities, developing custom sprites and moderating/admining
          private servers.
        </Text>
        <br></br>
        <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
          In the early 2010s, we all stumbled upon crypto. We have some haunting
          reciepts, paying $20-30 for 0.5 BTC. At that time we were too young to
          have any real money to spend, and instead continued our days through
          high school, each following year feeling the missing out feeling of
          not doing more with crypto back then.
        </Text>
        <br></br>
        <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
          After college, each of us moved to new places across the US but have
          continued to keep in touch. One thing we can always agree on is doing
          some sort of application development project. Thus, Crypto Toes were
          born. We studied Solidity, took some courses, build some testnet apps
          and before doubling down fell in love with Solana.
        </Text>
        <br></br>
        <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
          Since then we have worked tirelessly building out the randomization
          engine, learning the nuances of Metaplex, creating and drwaing assets,
          setting out our goals, plans, mission on how we launch this project,
          and are now actualizing it. Our wives have been amazingly patient with
          us and have started believing a tiny bit themselves.
        </Text>
        <br></br>
        <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
          Next we hope to make it in this space, and bring the toeDAO along with
          us.
        </Text>
        <br></br>
        <Text fontFamily="Poppins-Bold">WAGMI</Text>
        <br></br>
        <Box
          display="inline-flex"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          alignSelf="center"
        >
          <Image width="18vw" height="auto" src={'/Gabriel.png'} />
          <Text pl={12}>
            <Heading
              fontFamily={headingFontFamily}
              fontSize={subheadingFontSize}
            >
              Gabriel
            </Heading>
            <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
              Developer / Community
            </Text>
            <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
              <Link isExternal href="https://twitter.com/shcool_bad">
                @shcool_bad
              </Link>
            </Text>
            <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
              School Bad#3795
            </Text>
          </Text>
        </Box>
        <br></br>
        <Box
          display="inline-flex"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          alignSelf="center"
        >
          <Image width="15vw" height="auto" src={'/Nathan.png'} />
          <Text pl={12}>
            <Heading
              fontFamily={headingFontFamily}
              fontSize={subheadingFontSize}
            >
              Nathan
            </Heading>
            <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
              Designer / Community
            </Text>
            <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
              <Link isExternal href="https://twitter.com/toepixels">
                @toepixels
              </Link>
            </Text>
            <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
              Shancus#1512
            </Text>
          </Text>
        </Box>
        <br></br>
        <Box
          display="inline-flex"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          alignSelf="center"
        >
          <Image width="15vw" height="auto" src={'/Tyler.png'} />
          <br></br>
          <Text pl={12}>
            <Heading
              fontFamily={headingFontFamily}
              fontSize={subheadingFontSize}
            >
              Tyler
            </Heading>
            <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
              Developer / Community
            </Text>
            <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
              <Link isExternal href="https://twitter.com/RobertoeHallux">
                @RobertoeHallux
              </Link>
            </Text>
            <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
              RobertoeHallux#9754
            </Text>
          </Text>
        </Box>

        <br></br>
      </Box>
      {scrollPosition > 500 && (
        <Box
          position="fixed"
          bottom="20px"
          right={['16px', '84px']}
          zIndex={1}
          onClick={() => scrollToTop()}
          cursor="pointer"
        >
          <Image src="top.png" w="60px" h="60px" />
        </Box>
      )}
    </Box>
  );
}

export default Team;
