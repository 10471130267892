import React from 'react';
import { Box, Grid, GridItem, Heading, Text, Image } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// import { useBreakpointValue } from '@chakra-ui/media-query';

function FAQ() {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const headingFontSize = isMobile ? '28px' : '42px';
  const subheadingFontSize = isMobile ? '18px' : '24px';
  const headingFontColor = '#02549F';
  const headingFontFamily = 'Poppins-Bold';
  const bodyFontFamily = 'Poppins-Regular';
  const textFontSize = isMobile ? '12px' : '18px';
  // const textFontSize = '18px';

  React.useLayoutEffect(() => {
    const anchor = window.location.hash.slice(1);
    if (anchor) {
      const anchorEl = document.getElementById(anchor);
      if (anchorEl) {
        anchorEl.scrollIntoView();
      }
    }
  }, []);

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Box
        maxW="65vw"
        textAlign="start"
        fontSize="xl"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Heading
          alignSelf="start"
          fontFamily={headingFontFamily}
          fontSize={headingFontSize}
          color={headingFontColor}
          pt={24}
          id="toe"
        >
          Crypto Toes FAQs
        </Heading>
        <Box
          maxW="50vw"
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          my={16}
          fontFamily={bodyFontFamily}
          fontSize={textFontSize}
          letterSpacing="1px"
        >
          <Grid
            justifyContent="center"
            alignItems="center"
            gridTemplateColumns="auto"
          >
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                How many toes are there?
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                There will only be up to 7035 Crypto Toes at initial mint.
                Following the mint, the Wolf will begin snatching toes and
                gobbling them up.
              </Text>
            </GridItem>
            <br></br>
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                When is mint date?
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                The official mint date is:
                <br></br>
                November 30th at 7:03pm EST
                <br></br>
                December 1st, 12:03am UTC
              </Text>
            </GridItem>
            <br></br>
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                What is the minting price?
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                The price per toe will be 0.2 SOL, or 1 SOL per foot.
              </Text>
            </GridItem>
            <br></br>
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                Is there a whitelist?
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                No. Each toe is minted one at a time on public sale date.
                However, we will have several chances to win toes by
                participating in contests and giveaways. Holding a Crypto Toe
                will give whitelist access for future toeMART drops and
                collaborations.
              </Text>
            </GridItem>
            <br></br>
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                How were the toes made?
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                Our developers wrote code to randomly generate 7035 unique
                combinations of toes as well as layer the assets to make an
                image. On launch date, this script will be run, followed by the
                Metaplex candy machine script, to generate, upload, and make
                mintable all 7035 toes without human interaction.
              </Text>
            </GridItem>
            <br></br>
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                How will the mint work?
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                We will have a link announced in the chat, it will be this same
                URL. The toes will be generated, uploaded to the candy machine,
                and dispensed shuffled. On mint date, the first mint will not
                necessarily be Crypto Toe #1. This "shuffling" is coded into the
                Metaplex script and helps create transparency and fairness for
                everyone.
              </Text>
            </GridItem>
            <br></br>
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                What is the wolf?
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                You can read more about the wolf{' '}
                <NavLink to="/wolf" style={{ fontFamily: 'Poppins-Bold' }}>
                  here.
                </NavLink>
                <br></br>
              </Text>
            </GridItem>
            <br></br>
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                Where can I buy and sell toes?
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                We are in conversation with multiple markets (Magic Eden, Alpha
                Art, Digital Eyes) and will confirm these closer to mint date.
              </Text>
            </GridItem>
            <br></br>
            <br></br>
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                What is the toeDAO?
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                The toeDAO is the community of toe holders. This organization
                (you) will recieve a large percentage of the royalties, and will
                serve as the platform in which every subsequent drop will spring
                from. It is decentralized and self-governmed.
              </Text>
            </GridItem>
            <br></br>
          </Grid>
        </Box>
      </Box>
      <Box
        maxW="65vw"
        textAlign="start"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        fontFamily={bodyFontFamily}
        fontSize={textFontSize}
      >
        <Heading
          alignSelf="start"
          fontFamily={headingFontFamily}
          fontSize={headingFontSize}
          color={headingFontColor}
          pt={24}
          id="nft"
        >
          NFT / Crypto FAQs
        </Heading>
        <Box
          maxW="50vw"
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          my={8}
          fontFamily={headingFontFamily}
          letterSpacing="1px"
        >
          <Grid
            justifyContent="center"
            alignItems="center"
            gridTemplateColumns="auto"
          >
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                What is an NFT?
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                NFT stands for Non-Fungible Token. This is a fancy,
                cryptographic way to describe the creation of a singular, unique
                and clearly defined digital asset. For example, each of us have
                10 unique toes (usually) that are never duplicated or
                reproducible by others. While a lot of toes look similar, we can
                recognize that each individual toe is different in small, or
                sometimes very large, ways. We also know that our toes will
                never be recreated by another human and that they are entirely
                owned by our own bodies (usually).
              </Text>
              <br></br>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                An NFT is a way to create a digital certificate that represents
                each one of our individual 10 toes, a certificate of
                authenticity if you will. In theory, we now have a digital
                representation of a unique asset and this allows us to do some
                interesting things.
              </Text>
              <br></br>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                I could swap the digital asset of my toe for one of yours,
                showing the world that the unique appendage on my body actually
                “belongs” to you, and vice versa. I don’t know why you’d want my
                big toe, but that’s your business... as long as I can have yours
                too.. But no matter what happens to either of our real-life
                toes, we have a record proving ownership that is verifiable by
                its existence on the blockchain!
              </Text>
            </GridItem>
            <br></br>
            <br></br>
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                This little piggy went to the market…
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                NFTs are digital representations of assets, which means you can
                buy, sell, and trade them, just like trading cards, houses, or
                even toes! This piggy went to market early and was able to sell
                his toe for a slight profit! Good for him, but he’s missing out
                on something more...
              </Text>
            </GridItem>
            <br></br>
            <br></br>
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                This little piggy stayed home…
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                On the other hand.. or foot, this piggy chose to not get into
                the toe game at all! He’s missing out on something big and could
                have been one of the toeG’s that helped build a community. It’s
                a pity he didn’t join in when he first saw toeMART!
              </Text>
            </GridItem>
            <br></br>
            <br></br>
            <GridItem>
              <Heading
                mb={8}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                This little piggy got rug pulled…
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                One challenging aspect about getting into NFTs is rug pulls.
                This happens when one or more of the team members take any early
                monetary gains and disappear. This leaves the NFT holders with a
                ghost town of a community and no hopes of future development or
                support of the existing community. This also severly damages the
                NFT and Solana community, making cautious and relunctant buyers.
                Please, do your own research before buying into NFTs. Check out
                a collection's social media pages and discord, verify they are
                actually sending out giveaway prizes, you do not want to buy
                into a project that has no clear goals or values. If you suspect
                or spot a scam, report it everywhere you can!
              </Text>
            </GridItem>
            <br></br>
            <br></br>
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                This little piggy bought often...
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                There are plenty of other NFTs projects out in the Solana
                metaverse… and many other communities you can join. toeMART
                offers the promise of an authentic, home-grown community that
                welcomes both new and old members with Toe-nacity. You may find
                other quick flips that exist in the NFT world and we wish you
                success in those ventures! We hope toeMART and the toeDAO can
                become the home you come back to and tell the stories of scary
                wolves defeated!
              </Text>
            </GridItem>
            <br></br>
            <br></br>
            <GridItem>
              <Heading
                mb={4}
                fontFamily={headingFontFamily}
                fontSize={subheadingFontSize}
              >
                This little piggy went…
              </Heading>
            </GridItem>
            <GridItem>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                Wee wee wee, all the way home! Those who join the toeMART vision
                and hold their toes through thick and thin stand to build
                diamond homes of their own. Over time the Big Bad Wolf will hunt
                out some of the paper hands, making the dwindling survivors that
                much more Toe-nacious, but also that much more valuable. We
                welcome you wholeheartedly into the toe community and toeDAO as
                we trudge the road to happy destiny together.
              </Text>
            </GridItem>
            <br></br>
          </Grid>
        </Box>
        {scrollPosition > 500 && (
          <Box
            position="fixed"
            bottom="20px"
            right={['16px', '84px']}
            zIndex={1}
            cursor="pointer"
            onClick={() => scrollToTop()}
          >
            <Image src="top.png" w="60px" h="60px" />
          </Box>
        )}
      </Box>
    </>
  );
}

export default FAQ;
