import React from 'react';
import {
  Box,
  Text,
  Image,
  Heading,
  Button,
  useBreakpointValue,
  VStack,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

function Wolf() {
  const [story, setStory] = React.useState(true);
  const onceUpon = useBreakpointValue({ base: '2xl', md: '3xl' });
  const buttonColor = '#E62432';
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const headingFontSize = isMobile ? '28px' : '42px';
  const subheadingFontSize = isMobile ? '18px' : '24px';
  const headingFontColor = '#02549F';
  const headingFontFamily = 'Poppins-Bold';
  const bodyFontFamily = 'Poppins-Regular';
  const textFontSize = isMobile ? '12px' : '18px';
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Box
        maxW={'65vw'}
        textAlign="start"
        letterSpacing="2px"
        fontFamily={bodyFontFamily}
        fontSize="lg"
        id="top"
      >
        <VStack minH="100vh">
          <Box alignSelf="start">
            <Heading
              fontFamily={headingFontFamily}
              fontSize={headingFontSize}
              style={{ color: headingFontColor }}
              whiteSpace="nowrap"
              mt={12}
            >
              The Big Bad Wolf
            </Heading>
            <br></br>
            <Button
              size="md"
              fontFamily={'Poppins'}
              fontWeight="600"
              fontSize="2xl"
              onClick={() => setStory(!story)}
              backgroundColor={buttonColor}
              justifyContent="center"
              variant="ghost"
              _hover={{ bg: '#AD1B26' }}
            >
              {story ? (
                <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                  Read Technical Details
                </Text>
              ) : (
                <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                  Read Story
                </Text>
              )}
            </Button>
          </Box>
          {story ? (
            <>
              <Box display="flex">
                <b
                  style={{
                    fontSize: '160px',
                    fontFamily: 'UnifrakturMaguntia-Regular',
                    alignSelf: 'start',
                  }}
                >
                  O
                </b>
                <Box alignSelf="center">
                  <Heading fontSize={onceUpon}>nce upon a time...</Heading>
                  <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                    There were 3 little piggy brothers that set out to find
                    fortune in the NFT Metaverse. Each one was given an alpha
                    toe charm to bring them luck!
                  </Text>
                </Box>
              </Box>
              <Image
                maxW={'65%'}
                height="auto"
                src="./scene1.png"
                alt="Three pigs and a wolf"
              />
              <Box display="flex">
                <Text
                  fontFamily={bodyFontFamily}
                  fontSize={textFontSize}
                  alignSelf="center"
                  marginRight={8}
                >
                  The first little pig was very lazy, he sold his good luck
                  charm as soon as he reached the market. With his small
                  earnings, he built a house out of paper.
                </Text>
                <Image
                  maxW="50%"
                  height="auto"
                  objectFit="contain"
                  src="./scene2.png"
                  alt="Three pigs and a wolf"
                />
              </Box>
              <Box display="flex">
                <Image
                  maxW="50%"
                  height="auto"
                  objectFit="contain"
                  src="./scene3.png"
                  alt="Three pigs and a wolf"
                />
                <Text
                  fontFamily={bodyFontFamily}
                  fontSize={textFontSize}
                  marginLeft={8}
                  alignSelf="center"
                >
                  The second little pig waited a bit longer, but was convinced
                  by his younger brother to sell his charm as well in the next
                  town over. After selling his charm, the second pig took his
                  earnings and was able to build a house out of sticks.
                </Text>
              </Box>
              <Box display="flex">
                <Text
                  fontFamily={bodyFontFamily}
                  fontSize={textFontSize}
                  alignSelf="center"
                  marginRight={8}
                >
                  The third pig was much smarter and held onto his lucky toe
                  charm. With all the great luck it bestowed him, he was able to
                  win some giveaways, and buy NFTs to flip for profit. He
                  stumbled upon his two brothers' toe charms in the market, and
                  quickly snatched them up!
                </Text>
                <Image
                  maxW="50%"
                  height="auto"
                  objectFit="contain"
                  src="./scene4.png"
                  alt="Three pigs and a wolf"
                />
              </Box>
              <Image
                maxW={'65%'}
                height="auto"
                objectFit="contain"
                src="./scene5.png"
                alt="Three pigs and a wolf"
              />
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize} p={8}>
                With all of his newfound luck, he very quickly became wealthy
                beyond his wildest dreams and made a host of friends along the
                way. He built his home out of diamond and decorated it with all
                the things he could desire. The third pigs home was constantly
                full of light, laughter, and good times.
              </Text>
              <Image
                maxW={'65%'}
                height="auto"
                objectFit="contain"
                src="./scene6.png"
                alt="Three pigs and a wolf"
              />
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize} p={8}>
                Then one day the Big Bad Wolf came to town, hungry for some
                little piggies. He strolled down the lane and came across the
                first piggy in his house of paper. He knocked on the door and
                demanded to be let in!
              </Text>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize} p={8}>
                “Not by the hair, on my toey-toe-toe!” Shouted the first little
                piggy. But he didn’t have his charm and his luck had run out.
                The Big Bad Wolf huffed, then he puffed, then he blew the paper
                house down! Before he could be caught, the first little pig made
                it to his brother’s house made of sticks.
              </Text>
              <Image
                maxW={'65%'}
                objectFit="contain"
                height="auto"
                src="./scene7.png"
                alt="Three pigs and a wolf"
              />
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize} p={8}>
                Chasing his prey all the way to the house made of sticks, he
                repeated his demand of being let in or else! “Not by the hair on
                our toey-toe-toes!” shouted the brothers in unison. Surely the
                house of sticks would be strong enough to protect them from the
                Big Bad Wolf! (Narrator: it wasn’t)
              </Text>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize} p={8}>
                The Big Bad Wolf huffed, he puffed, and he blew the stick house
                down chasing after the piggies as they ran for the hills.
              </Text>
              <Image
                maxW={'65%'}
                height="auto"
                src="./scene8.png"
                objectFit="contain"
                alt="Three pigs and a wolf"
              />
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize} p={8}>
                The two brothers burst into their remaining brother’s diamond
                house, warning him and everyone there that they all must leave
                behind their belongings and run or else the Big Bad Wolf would
                get them! The third brother resisted the urge though and simply
                let his brothers run away without him. When the Big Bad Wolf
                arrived at the diamond house he made his demand to be let in.
              </Text>
              <Text fontFamily={bodyFontFamily} fontSize={textFontSize} p={8}>
                “Not by the hair, on my toey-toe-toes!” shouted the brother.
                This time, no matter how hard he huffed, or how much he puffed,
                the Big Bad Wolf wasn’t able to blow the diamond house down.
                After struggling and searching around the home for weaknesses,
                the Big Bad Wolf found the trail of the two brothers who ran
                away.
              </Text>
              <Box display="flex">
                <Image
                  maxW={'50%'}
                  height="auto"
                  objectFit="contain"
                  src="./scene9.png"
                  alt="Three pigs and a wolf"
                />
                <Text
                  fontFamily={bodyFontFamily}
                  fontSize={textFontSize}
                  alignSelf="center"
                  p={8}
                >
                  Instead of fussing around with the house made of diamond, the
                  Big Bad Wolf ran down the lane after the two brothers who
                  weren’t smart enough to save their toe charms!
                </Text>
              </Box>
              <Image
                maxW={'65%'}
                objectFit="contain"
                height="auto"
                src="./scene10.png"
                alt="Three pigs and a wolf"
              />
            </>
          ) : (
            <>
              <Box
                alignSelf="start"
                fontFamily={bodyFontFamily}
                fontSize={textFontSize}
              >
                <br></br>
                <Heading
                  fontSize={headingFontSize}
                  style={{ color: headingFontColor }}
                  fontFamily={headingFontFamily}
                >
                  There will only ever be 703 TOES...
                </Heading>
                However, just as in our story, there is a wolf roaming the
                Solana metaverse looking for little piggies to snack on...
              </Box>
              <br></br>
              <Box alignSelf="start">
                <Heading pb={4} fontSize={subheadingFontSize} color="black">
                  Deflation
                </Heading>
                <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                  <UnorderedList>
                    <ListItem>
                      After the toes are minted, the wolf begins hunting.
                    </ListItem>
                    <ListItem>Every single toe is random and unique.</ListItem>
                    <ListItem>
                      Every toe is at risk of being destroyed and gobbled up by
                      the wolf!
                    </ListItem>
                    <ListItem>
                      This makes toeMART Crypto Toes a <b>deflationary</b>{' '}
                      collection.
                    </ListItem>
                  </UnorderedList>
                </Text>
              </Box>
              <br></br>
              <Box alignSelf="start">
                <Heading pb={4} fontSize={subheadingFontSize} color="black">
                  Post Mint
                </Heading>
                <Text
                  fontFamily={bodyFontFamily}
                  fontSize={textFontSize}
                  wordBreak="none"
                >
                  <UnorderedList>
                    <ListItem>
                      A portion of the mint and aftermarket sales revenue will
                      be directly deposited into the Wolf's wallet.
                    </ListItem>
                    <ListItem>
                      This will allow him to sweep (purchase) the floor of the
                      underpriced and lowest listed toes.
                    </ListItem>
                    <ListItem>
                      Once he has grabbed some little piggies from the market,
                      he will destroy them forever.
                    </ListItem>
                    <ListItem>
                      This will in turn rewards diamond toe hodlers, driving up
                      the floor price while simultaneously reducing the supply.
                    </ListItem>
                  </UnorderedList>
                </Text>
              </Box>
              <br></br>
              <Box>
                <Heading pb={4} fontSize={subheadingFontSize} color="black">
                  Stay Safe
                </Heading>
                <Text fontFamily={bodyFontFamily} fontSize={textFontSize}>
                  <UnorderedList>
                    <ListItem>
                      Holding onto your toes is a surefire way to keep them safe
                      from the Wolf.
                    </ListItem>
                    <ListItem>
                      Hold on to your toes like the third piggy for good luck!
                    </ListItem>
                    <ListItem>
                      Collaborative NFTs, and other tokens will be airdropped to
                      our diamond toe holders.
                    </ListItem>
                    <ListItem>
                      Unique 1/1 NFTs may be airdropped to the first few{' '}
                      <i>diamond feet</i> hodlers who have 5 or more toes.
                    </ListItem>
                  </UnorderedList>
                </Text>
              </Box>
              <br></br>
              <Box alignSelf="start">
                <Heading pb={4} fontSize={subheadingFontSize} color="black">
                  Have Fun, Diamond Toes
                </Heading>
                <UnorderedList
                  fontFamily={bodyFontFamily}
                  fontSize={textFontSize}
                >
                  <ListItem>
                    You will become a founding member in our toeDAO.
                  </ListItem>
                  <ListItem>
                    You will become eligible to recieve future airdrops.
                  </ListItem>
                  <ListItem>
                    You will benefit from future collaborations and giveaways.
                  </ListItem>
                  <ListItem>
                    You are essential in helping us cultivate an amazing
                    community.
                  </ListItem>
                  <ListItem>
                    You will find new friends sprout up all around you.
                  </ListItem>
                </UnorderedList>
              </Box>
            </>
          )}
        </VStack>
        {scrollPosition > 500 && (
          <Box
            position="fixed"
            bottom="20px"
            right={['16px', '84px']}
            zIndex={1}
            onClick={() => scrollToTop()}
            cursor="pointer"
          >
            <Image src="top.png" w="60px" h="60px" />
          </Box>
        )}
      </Box>
    </>
  );
}

export default Wolf;
