import React from 'react';
import {
  Box,
  Flex,
  Link,
  Icon,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Image,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';

export default function Navbar() {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  return (
    <Box
      style={{
        backgroundImage: 'url(./navbar.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        backgroundSize: 'auto 100%',
      }}
      px={4}
      position="relative"
      width="100vw"
      zIndex="1"
    >
      {isMobile ? (
        <>
          <Flex alignItems={'center'}>
            <Box marginBottom={12} cursor="pointer">
              <NavLink to="/">
                <Image src="/logo.png" w="110px" />
              </NavLink>
            </Box>
            <Stack height="177px" direction={'row'} spacing={7}>
              <Menu>
                <MenuButton
                  position="absolute"
                  marginTop={12}
                  right={8}
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}
                  height="1.5rem"
                  textDecoration="none"
                >
                  <HamburgerIcon
                    marginTop={0}
                    boxSize={'1.5rem'}
                    color="black"
                  />
                </MenuButton>
                <MenuList
                  style={{
                    backgroundColor: '#FDF0B1',
                    borderColor: 'transparent',
                  }}
                  alignItems={'center'}
                >
                  <Link as={NavLink} to="/#toe">
                    <MenuItem>About</MenuItem>
                  </Link>
                  <Link as={NavLink} to="/#next">
                    <MenuItem>What's Next</MenuItem>
                  </Link>
                  <Link as={NavLink} to="/wolf">
                    <MenuItem>The Big Bad Wolf</MenuItem>
                  </Link>
                  <Link as={NavLink} to="/faq#toe">
                    <MenuItem>Crypto Toes FAQ</MenuItem>
                  </Link>
                  <Link as={NavLink} to="/faq#nft">
                    <MenuItem>NFT/Crypto FAQ</MenuItem>
                  </Link>
                  <Link
                    as={Link}
                    isExternal
                    href="https://moonrank.app/collection/crypto_toes"
                  >
                    <MenuItem>Rarity</MenuItem>
                  </Link>
                  <Link as={NavLink} to="/roadmap">
                    <MenuItem>Roadmap</MenuItem>
                  </Link>
                  <MenuItem display="flex" justifyContent="space-evenly">
                    <Link
                      width="20%"
                      isExternal
                      href="https://twitter.com/toeMART"
                    >
                      <Icon as={FaTwitter} />
                    </Link>
                    <Link
                      width="20%"
                      isExternal
                      href="https://discord.com/invite/Ct8R7uwubQ"
                    >
                      <Icon as={FaDiscord} />
                    </Link>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </Flex>
        </>
      ) : (
        <>
          <Flex
            paddingLeft="15px"
            paddingBottom="50px"
            h={177}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box cursor="pointer">
              <NavLink to="/">
                <Image src="/logo.png" w="110px" />
              </NavLink>
            </Box>
            <Flex alignItems={'center'}>
              <Stack
                color="#02549F"
                fontFamily="Poppins-Light"
                direction={'row'}
                spacing={2}
              >
                <Menu>
                  <MenuButton
                    fontSize="sm"
                    px={4}
                    fontFamily="Poppins-Light"
                    _hover={{ bg: '#FBE88B' }}
                    borderRadius="md"
                    _expanded={{ bg: '#FBE88B' }}
                    fontWeight="600"
                  >
                    Home
                  </MenuButton>
                  <MenuList backgroundColor="#FBE88B">
                    <MenuItem as={NavLink} to="/#toe">
                      About
                    </MenuItem>
                    <MenuItem>
                      <Link href="/#next" style={{ textDecoration: 'none' }}>
                        What's Next
                      </Link>
                    </MenuItem>
                  </MenuList>
                </Menu>
                <Button
                  _hover={{ bg: '#FBE88B' }}
                  as={NavLink}
                  to="/wolf"
                  color="#02549F"
                  fontSize="sm"
                  variant="ghost"
                  fontFamily="Poppins-Regular"
                >
                  The Big Bad Wolf
                </Button>
                <Menu>
                  <MenuButton
                    fontSize="sm"
                    px={4}
                    fontFamily="Poppins-Light"
                    _hover={{ bg: '#FBE88B' }}
                    borderRadius="md"
                    _expanded={{ bg: '#FBE88B' }}
                    fontWeight="600"
                  >
                    FAQs
                  </MenuButton>
                  <MenuList backgroundColor="#FBE88B">
                    <MenuItem as={NavLink} to="/faq#toe">
                      <Link href="/faq#toe" style={{ textDecoration: 'none' }}>
                        Crypto Toes
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link href="/faq#nft" style={{ textDecoration: 'none' }}>
                        NFT / Crypto
                      </Link>
                    </MenuItem>
                  </MenuList>
                </Menu>
                <Button
                  _hover={{ bg: '#FBE88B' }}
                  as={Link}
                  variant="ghost"
                  color="#02549F"
                  isExternal
                  href="https://moonrank.app/collection/crypto_toes"
                  fontSize="sm"
                >
                  Rarity
                </Button>
                <Button
                  _hover={{ bg: '#FBE88B' }}
                  as={NavLink}
                  to="/roadmap"
                  variant="ghost"
                  color="#02549F"
                  fontSize="sm"
                >
                  Roadmap
                </Button>
                <Button
                  _hover={{ bg: '#FBE88B' }}
                  as={Link}
                  isExternal
                  color="#02549F"
                  variant="ghost"
                  href="https://discord.gg/Ct8R7uwubQ"
                >
                  <Icon as={FaDiscord} />
                </Button>
                <Button
                  _hover={{ bg: '#FBE88B' }}
                  as={Link}
                  isExternal
                  color="#02549F"
                  variant="ghost"
                  href="https://twitter.com/toeMART"
                >
                  <Icon as={FaTwitter} />
                </Button>
              </Stack>
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  );
}
