import React from 'react';
import { Box, Grid, GridItem, Heading, Text, Image } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/media-query';

function Why() {
  const headingFont = useBreakpointValue({ base: '3xl', md: '5xl' });
  const headingFont2 = useBreakpointValue({ base: '2xl', md: '3xl' });
  const headingFontWeight = useBreakpointValue({ base: '300', md: '300' });
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box
      width="100vw"
      textAlign="start"
      fontSize="xl"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Heading
        fontFamily="Poppins"
        fontSize={headingFont}
        fontWeight={headingFontWeight}
        pt={24}
      >
        Whats next for toeMART?
      </Heading>
      <Box
        maxW="50vw"
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        my={16}
        fontFamily="Poppins"
        letterSpacing="1px"
      >
        <Grid
          justifyContent="center"
          alignItems="center"
          gridTemplateColumns="auto"
          gridTemplateRows="repeat(16, .25fr)"
        >
          <GridItem>
            <Heading
              fontFamily="Poppins"
              fontSize={headingFont2}
              fontWeight={headingFontWeight}
            >
              Establish toeDAO
            </Heading>
          </GridItem>
          <GridItem>
            <Text>
              Our first course of action post-mint is to double down into our
              community. A large portion of mint sales and post mint fees go
              directly to the purse of our toeDAO.
            </Text>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              fontFamily="Poppins"
              fontSize={headingFont2}
              fontWeight={headingFontWeight}
            >
              Establish connections and collaborations
            </Heading>
          </GridItem>
          <GridItem>
            <Text>
              Following our own creation, we will start to look ahead of us for
              leaders in the space who have been successful and seek
              relationships and collaborations to reward our diamond toes.
            </Text>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              fontFamily="Poppins"
              fontSize={headingFont2}
              fontWeight={headingFontWeight}
            >
              Cultivate an open source community
            </Heading>
          </GridItem>
          <GridItem>
            <Text>
              A big goal of ours is to be able to contribute to the Solana open
              source community. Metaplex is an amazing tool, there are amazing
              frontends open soruced, we want to add to that by example of open
              sourcing our deviations and implementations of this software.
            </Text>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              fontFamily="Poppins"
              fontSize={headingFont2}
              fontWeight={headingFontWeight}
            >
              Airdrops, giveaways, alpha
            </Heading>
          </GridItem>
          <GridItem>
            <Text>
              As the toeDAO establishes itself, we will earnestly reward them
              with information of our next drops, exclusive sneak peaks and
              giveaways, and bring in beta testing for our on and off chain
              endeavours.
            </Text>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              fontFamily="Poppins"
              fontSize={headingFont2}
              fontWeight={headingFontWeight}
            >
              ?????????
            </Heading>
          </GridItem>
          <GridItem>
            <Text>
              It is hard to know exactly how things will map out, but the team
              at toeMART has been friends for twenty years and we hope to
              radiate that energy through every move we make. We are here for
              the long haul and are excited to meet you all along the way!
            </Text>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              fontFamily="Poppins"
              fontSize={headingFont2}
              fontWeight={headingFontWeight}
            >
              Next Drop
            </Heading>
          </GridItem>
          <GridItem>
            <Text>TBD</Text>
          </GridItem>
          <br></br>
        </Grid>
      </Box>
      {scrollPosition > 500 && (
        <Box
          position="fixed"
          bottom="20px"
          right={['16px', '84px']}
          zIndex={1}
          onClick={() => scrollToTop()}
          cursor="pointer"
        >
          <Image src="top.png" w="60px" h="60px" />
        </Box>
      )}
    </Box>
  );
}
export default Why;
