import React from 'react';
import { Box, Grid, GridItem, Heading, Text, Image } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/media-query';

function What() {
  const headingFont = useBreakpointValue({ base: '3xl', md: '5xl' });
  const headingFont2 = useBreakpointValue({ base: '2xl', md: '3xl' });
  const headingFontWeight = useBreakpointValue({ base: '300', md: '300' });
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box
      width="100vw"
      textAlign="start"
      fontSize="xl"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Heading
        fontFamily="Poppins"
        fontSize={headingFont}
        fontWeight={headingFontWeight}
        pt={24}
      >
        NFT / Crypto FAQs
      </Heading>
      <Box
        maxW="50vw"
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        my={16}
        fontFamily="Poppins"
        letterSpacing="1px"
      >
        <Grid
          justifyContent="center"
          alignItems="center"
          gridTemplateColumns="auto"
          gridTemplateRows="repeat(13, .25fr)"
        >
          <GridItem>
            <Heading
              fontFamily="Poppins"
              fontSize={headingFont2}
              fontWeight={headingFontWeight}
            >
              What is an NFT?
            </Heading>
          </GridItem>
          <GridItem>
            <Text>
              NFT stands for Non-Fungible Token. This is a fancy, cryptographic
              way to describe the creation of a singular, unique and clearly
              defined digital asset. For example, each of us have 10 unique toes
              (usually) that are never duplicated or reproducible by others.
              While a lot of toes look similar, we can recognize that each
              individual toe is different in small, or sometimes very large,
              ways. We also know that our toes will never be recreated by
              another human and that they are entirely owned by our own bodies
              (usually).
            </Text>
            <br></br>
            <Text>
              An NFT is a way to create a digital certificate that represents
              each one of our individual 10 toes, a certificate of authenticity
              if you will. In theory, we now have a digital representation of a
              unique asset and this allows us to do some interesting things.
            </Text>
            <br></br>
            <Text>
              I could swap the digital asset of my toe for one of yours, showing
              the world that the unique appendage on my body actually “belongs”
              to you, and vice versa. I don’t know why you’d want my big toe,
              but that’s your business... as long as I can have yours too.. But
              no matter what happens to either of our real-life toes, we have a
              record proving ownership that is verifiable by its existence on
              the blockchain!
            </Text>
          </GridItem>

          <GridItem>
            <Heading
              fontFamily="Poppins"
              fontSize={headingFont2}
              fontWeight={headingFontWeight}
            >
              This little piggy went to the market…
            </Heading>
          </GridItem>
          <GridItem>
            <Text>
              NFTs are digital representations of assets, which means you can
              buy, sell, and trade them, just like trading cards, houses, or
              even toes! This piggy went to market early and was able to sell
              his toe for a slight profit! Good for him, but he’s missing out on
              something more...
            </Text>
          </GridItem>

          <GridItem>
            <Heading
              fontFamily="Poppins"
              fontSize={headingFont2}
              fontWeight={headingFontWeight}
            >
              This little piggy stayed home…
            </Heading>
          </GridItem>
          <GridItem>
            <Text>
              On the other hand.. or foot, this piggy chose to not get into the
              toe game at all! He’s missing out on something big and could have
              been one of the toeG’s that helped build a community. It’s a pity
              he didn’t join in when he first saw toeMART!
            </Text>
          </GridItem>
          <GridItem>
            <Heading
              fontFamily="Poppins"
              fontSize={headingFont2}
              fontWeight={headingFontWeight}
            >
              This little piggy got rug pulled…
            </Heading>
          </GridItem>
          <GridItem>
            <Text>
              One challenging aspect about getting into NFTs is rug pulls. This
              happens when one or more of the team members take any early
              monetary gains and disappear. This leaves the NFT holders with a
              ghost town of a community and no hopes of future development or
              support of the existing community. This also severly damages the
              NFT and Solana community, making cautious and relunctant buyers.
              Please, do your own research before buying into NFTs. Check out a
              collection's social media pages and discord, verify they are
              actually sending out giveaway prizes, you do not want to buy into
              a project that has no clear goals or values. If you suspect or
              spot a scam, report it everywhere you can!
            </Text>
          </GridItem>
          <GridItem>
            <Heading
              fontFamily="Poppins"
              fontSize={headingFont2}
              fontWeight={headingFontWeight}
            >
              This little piggy bought often...
            </Heading>
          </GridItem>
          <GridItem>
            <Text>
              There are plenty of other NFTs projects out in the Solana
              metaverse… and many other communities you can join. toeMART offers
              the promise of an authentic, home-grown community that welcomes
              both new and old members with Toe-nacity. You may find other quick
              flips that exist in the NFT world and we wish you success in those
              ventures! We hope toeMART and the toeDAO can become the home you
              come back to and tell the stories of scary wolves defeated!
            </Text>
          </GridItem>
          <GridItem>
            <Heading
              fontFamily="Poppins"
              fontSize={headingFont2}
              fontWeight={headingFontWeight}
            >
              This little piggy went…
            </Heading>
          </GridItem>
          <GridItem>
            <Text>
              Wee wee wee, all the way home! Those who join the toeMART vision
              and hold their toes through thick and thin stand to build diamond
              homes of their own. Over time the Big Bad Wolf will hunt out some
              of the paper hands, making the dwindling survivors that much more
              Toe-nacious, but also that much more valuable. We welcome you
              wholeheartedly into the toe community and toeDAO as we trudge the
              road to happy destiny together.
            </Text>
          </GridItem>
        </Grid>
      </Box>
      {scrollPosition > 500 && (
        <Box
          position="fixed"
          bottom="20px"
          right={['16px', '84px']}
          zIndex={1}
          onClick={() => scrollToTop()}
          cursor="pointer"
        >
          <Image src="top.png" w="60px" h="60px" />
        </Box>
      )}
    </Box>
  );
}

export default What;
