import React from 'react';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import { Route, Routes } from 'react-router';
import theme from './theme';
import Home from './pages/Home';
import Roadmap from './pages/Roadmap';
import FAQ from './pages/FAQ';
import What from './pages/What';
import Why from './pages/Why';
import Mint from './pages/Mint';
import Team from './pages/Team';
import Wolf from './pages/Wolf';
import Navbar from './components/Navbar/Navbar';
import toes from './toes.json';
import rarity from './rarity.json';

function App() {
  const makeData = () => {
    let newToes = toes;
    let newData = [];
    for (let toe of newToes) {
      let newToe = {};
      newToe['name'] = toe['name'];
      newToe['image'] = toe['image'];
      newToe['rank'] = toe['rank'];
      newToe['attributes'] = toe['attributes'];
      // newToe['attributes'] = 'Working on it';
      newData.push(newToe);
    }
    return newData;
  };

  let data = makeData();

  const columns = [
    {
      Header: 'Rank',
      accessor: 'rank',
      isNumeric: true,
    },
    {
      Header: 'Toe',
      accessor: 'name',
    },
    {
      Header: 'Image',
      Cell: row => {
        return (
          <div>
            {/* {console.log(row, 'ADEDAEFAEF')} */}
            <img
              height="80px"
              alt="a funny toe"
              width="80px"
              src={row['row']['original']['image']}
            />
          </div>
        );
      },
      accessor: 'image',
    },
    {
      Header: 'Attributes',
      Cell: row => {
        return (
          <div
            style={{
              fontSize: '14px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {row['row']['original']['attributes'].map(attribute => {
              return (
                <text style={{ paddingBottom: '0px' }}>
                  <b>{attribute['trait_type']}: </b>
                  {attribute['value']} (1 of
                  {' ' +
                    rarity[`${attribute['value']}-${attribute['trait_type']}`]}
                  )
                </text>
              );
            })}
          </div>
        );
      },
      accessor: 'attributes',
    },
  ];
  return (
    <ChakraProvider theme={theme}>
      <Flex
        minW="100%"
        style={{
          color: 'black',
          backgroundColor: '#EDF2F8',
        }}
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/crypto" element={<What />} />
          <Route path="/next" element={<Why />} />
          <Route
            path="/rarity"
            element={<Mint data={data} columns={columns} />}
          />
          <Route path="/team" element={<Team />} />
          <Route path="/wolf" element={<Wolf />} />
        </Routes>
        <div>
          <img
            alt="Hills"
            src="./footer.png"
            style={{
              backgroundColor: 'transparent',
            }}
          />
        </div>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
