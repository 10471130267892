import React from 'react';
import { Text, Heading, Box, Grid, GridItem, Image } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';
// import { NavLink } from 'react-router-dom';

function Home() {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const headingFontSize = isMobile ? '28px' : '42px';
  const subheadingFontSize = isMobile ? '18px' : '24px';
  const headingFontColor = '#02549F';
  const headingFontFamily = 'Poppins-Bold';
  const bodyFontFamily = 'Poppins-Regular';
  const textFontSize = isMobile ? '12px' : '18px';
  React.useLayoutEffect(() => {
    const anchor = window.location.hash.slice(1);
    if (anchor) {
      const anchorEl = document.getElementById(anchor);
      if (anchorEl) {
        anchorEl.scrollIntoView();
      }
    }
  }, []);

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Box
      width="100vw"
      textAlign="start"
      fontSize="xl"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      fontFamily={bodyFontFamily}
    >
      <Box
        maxW="60vw"
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        // my={16}
      >
        <Grid
          justifyContent="center"
          alignItems="center"
          gridTemplateColumns="auto"
        >
          <br></br>
          <br></br>
          <GridItem alignSelf="center" justifySelf="center">
            <Box alignItems="baseline" justifyItems="center" display="flex">
              <Image
                marginRight="-10px"
                width="30vw"
                height="auto"
                src="./latest.gif"
              />
              <Image
                marginLeft="-10px"
                width="20vw"
                height="auto"
                src="./bizpig.png"
              />
            </Box>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              color={headingFontColor}
              fontSize={headingFontSize}
              fontFamily={headingFontFamily}
              id="toe"
            >
              What is toeMART? And what are Crypto Toes?
            </Heading>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              pb={4}
              fontSize={subheadingFontSize}
              fontFamily={headingFontFamily}
              color="black"
            >
              This little piggy is going to market...
            </Heading>
          </GridItem>
          <GridItem>
            <Text fontSize={textFontSize} fontFamily={bodyFontFamily} pb={4}>
              703 randomly generated toes have populated the Solana metaverse..
            </Text>
            <Text fontSize={textFontSize} fontFamily={bodyFontFamily} pb={4}>
              But beware little piggies..
            </Text>
            <Text
              justifyItems="end"
              fontSize={textFontSize}
              fontFamily={bodyFontFamily}
              pb={4}
            >
              A {<b>wolf</b>} hunts these woods..
            </Text>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              color={headingFontColor}
              fontSize={headingFontSize}
              fontFamily={headingFontFamily}
              id="next"
            >
              Whats next for toeMART?
            </Heading>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              pb={4}
              fontSize={subheadingFontSize}
              fontFamily={headingFontFamily}
              color="black"
            >
              Establish toeDAO
            </Heading>
          </GridItem>
          <GridItem>
            <Text fontSize={textFontSize} fontFamily={bodyFontFamily} pb={4}>
              Our first course of action post-mint is to double down into our
              community. A large portion of mint sales and post mint fees go
              directly to the purse of our toeDAO.
            </Text>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              pb={4}
              fontSize={subheadingFontSize}
              fontFamily={headingFontFamily}
              color="black"
            >
              Establish connections and collaborations
            </Heading>
          </GridItem>
          <GridItem>
            <Text fontSize={textFontSize} fontFamily={bodyFontFamily} pb={4}>
              Following our own creation, we will start to look ahead of us for
              leaders in the space who have been successful and seek
              relationships and collaborations to reward our diamond toes.
            </Text>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              pb={4}
              fontSize={subheadingFontSize}
              fontFamily={headingFontFamily}
              color="black"
            >
              Cultivate an open source community
            </Heading>
          </GridItem>
          <GridItem>
            <Text fontSize={textFontSize} fontFamily={bodyFontFamily} pb={4}>
              A big goal of ours is to be able to contribute to the Solana open
              source community. Metaplex is an amazing tool, there are amazing
              frontends open soruced, we want to add to that by example of open
              sourcing our deviations and implementations of this software.
            </Text>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              pb={4}
              fontSize={subheadingFontSize}
              fontFamily={headingFontFamily}
              color="black"
            >
              Airdrops, giveaways, alpha
            </Heading>
          </GridItem>
          <GridItem>
            <Text fontSize={textFontSize} fontFamily={bodyFontFamily} pb={4}>
              As the toeDAO establishes itself, we will earnestly reward them
              with information of our next drops, exclusive sneak peaks and
              giveaways, and bring in beta testing for our on and off chain
              endeavours.
            </Text>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              pb={4}
              fontSize={subheadingFontSize}
              fontFamily={headingFontFamily}
              color="black"
            >
              ?????????
            </Heading>
          </GridItem>
          <GridItem>
            <Text fontSize={textFontSize} fontFamily={bodyFontFamily} pb={4}>
              It is hard to know exactly how things will map out, but the team
              at toeMART has been friends for twenty years and we hope to
              radiate that energy through every move we make. We are here for
              the long haul and are excited to meet you all along the way!
            </Text>
          </GridItem>
          <br></br>
          <GridItem>
            <Heading
              pb={4}
              fontSize={subheadingFontSize}
              fontFamily={headingFontFamily}
              color="black"
            >
              Next Drop
            </Heading>
          </GridItem>
          <GridItem>
            <Text fontSize={textFontSize} fontFamily={bodyFontFamily}>
              TBD
            </Text>
          </GridItem>
        </Grid>
      </Box>
      {scrollPosition > 500 && (
        <Box
          position="fixed"
          bottom="20px"
          right={['16px', '84px']}
          zIndex={1}
          onClick={() => scrollToTop()}
          cursor="pointer"
        >
          <Image src="top.png" w="60px" h="60px" />
        </Box>
      )}
    </Box>
  );
}

export default Home;
